// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// import 'sweetalert2/src/sweetalert2.scss'

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {color: $blue;}
.indigo {color: $indigo;}
.purple {color: $purple;}
.pink {color: $pink;}
.red {color: $red;}
.orange {color: $orange;}
.yellow {color: $yellow;}
.green {color: $green;}
.teal {color: $teal;}
.cyan {color: $cyan;}


// .content-header{
//   padding: 10px 0.5rem !important;
// }

/* bootstrap 4 panel */
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #fff;
}

.panel-body {
    padding: 15px;
    color: rgb(41, 43, 44);
    background-color: transparent;
}


/* colorize */
.panel-default { border-color: rgb(221, 221, 221); }
.panel-default > .panel-heading {
    color: rgb(51, 51, 51);
    background-color: rgb(245, 245, 245);
    border-color: rgb(221, 221, 221);
}
.panel-primary { border-color: #0275d8; }
.panel-primary > .panel-heading {
    background-color: #0275d8;
    border-color: #0275d8;
}
.panel-success { border-color: #5cb85c; }
.panel-success > .panel-heading {
    background-color: #5cb85c;
    border-color: #5cb85c;
}
.panel-info { border-color: #5bc0de; }
.panel-info > .panel-heading {
    background-color: #5bc0de;
    border-color: #5bc0de;
}
.panel-warning { border-color: #f0ad4e; }
.panel-warning > .panel-heading {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}
.panel-danger { border-color: #d9534f; }
.panel-danger > .panel-heading {
    background-color: #d9534f;
    border-color: #d9534f;
}
